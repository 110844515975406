import React from "react";
import moment from "moment";
import Layout from "../components/layout/layout";
// import SEO from "../components/seo";
import SEO from "../components/seo-mspi";
import { Link } from "gatsby";

import { FaFacebookF } from "react-icons/fa";

import ReactHtmlParser from "react-html-parser";

const EventPageTemplate = ({ pageContext: { event } }) => {
  console.log("********** page template page context props *********: ", event);
  // const formattedDate = moment(event.published_at).format("Do MMM YYYY")

  return (
    <>
      {/* --- background pattern --- */}
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>

      <Layout>
        <SEO
          title={event.eventTitle}
          image={event.eventPhoto}
          imageAlt={event.eventTitle}
        />
        {/* <SEO
          title="SWA Laughter Yoga Wellness Club"
          description="SWA laughter yoga to bring more laughter and joy for our members through the daily practice of Laughter Yoga to get physical, mental and emotional benefits of laughter."
          image="https://res.cloudinary.com/dkm14ge3g/image/upload/v1638592479/Logo/frjljjfxl0xzxxmn3wal.webp"
          imageAlt="SWA Laughter Yoga Wellness Club"
        /> */}
        <section className="max-w-2xl px-2 mx-auto mb-10 sm:max-w-3xl">
          <div className="mb-10 space-y-1 text-sm font-light text-gray-700">
            <div>
              <span className="font-bold">Programme: </span>
              {event?.programme}
            </div>
            <div>
              <span className="font-bold">Date: </span>
              {moment(event?.eventDate).format("LL")}
            </div>

            {event?.author && (
              <div className="flex items-center ">
                <div className="flex space-x-1">
                  <span className="font-bold">Author: </span>
                  <span>{event?.author?.name}</span>

                  <img
                    src={event?.author?.imgURL}
                    alt={event?.author?.name}
                    className="w-5 h-5 rounded-full"
                  />
                </div>
              </div>
            )}
          </div>
          <h1 className="mb-2 text-swa-3">{event.eventTitle}</h1>
          <div className="text-xl leading-8 text-gray-600">
            {ReactHtmlParser(event.eventSummary)}
          </div>
          <div className="mb-5 ">
            <img
              src={event.eventPhoto}
              alt="event photo"
              className="object-contain p-3 mx-auto "
            />
          </div>

          {event?.event.map((item, i) => {
            // const convertedDate = moment(item.eventDate).format("LL");
            switch (item.type) {
              case "singleTitle":
                return (
                  <div key={i} className="max-w-2xl mb-5 sm:w-4/5">
                    <h3 className="mb-2 text-swa-3">{item.title}</h3>
                  </div>
                );

              case "url":
                return (
                  <button className="mb-10 btn-dark" key={i}>
                    <Link to={item.url}>More info</Link>
                  </button>
                );

              case "singleParagraph":
                return (
                  <div
                    key={i}
                    className={`${
                      item.paragraphWidth === "wide" ? "w-full" : "max-w-2xl"
                    } px-2 mx-auto mb-5 text-xl leading-8 text-gray-500`}
                  >
                    {item.title && (
                      <h3 className="mb-2 text-swa-3">{item.title}</h3>
                    )}

                    {item.sidePhoto && (
                      <div
                        className={`${
                          item.sidePhotoPosition === "left"
                            ? "float-left mr-2"
                            : "float-right ml-2"
                        } mt-1`}
                      >
                        <img
                          src={item.sidePhoto}
                          alt={item.sideCaption}
                          className={`object-cover object-center w-72`}
                        />
                        <span className="text-sm text-gray-500 ">
                          {item.sideCaption}
                        </span>
                      </div>
                    )}
                    <p>{item.paragraph}</p>
                  </div>
                );
              case "simpleParagraph":
                return (
                  <div
                    key={i}
                    className={`${
                      item.paragraphWidth === "wide" ? "w-full" : "max-w-xl"
                    } px-2 mx-auto mb-5 text-xl leading-8 text-gray-500`}
                  >
                    <p>{item.simpleParagraph}</p>
                  </div>
                );
              case "singlePhoto":
                return (
                  <div
                    key={i}
                    className={`${
                      item.photoWidth === "wide" ? "w-full" : "max-w-xl"
                    } px-2 mx-auto mb-5`}
                  >
                    <img
                      src={item.image}
                      alt={item.caption}
                      className="w-full p-3 mx-auto border border-gray-200"
                    />
                    <p className="text-sm text-left text-gray-600 ">
                      {item.caption}
                    </p>
                  </div>
                );
              case "groupPhotos": {
                return (
                  <div key={i} className="px-2 mx-auto mb-5 sm:w-full">
                    <div className="bg-gray-100 gallery">
                      {item.photos.img.map((photo, i) => {
                        return (
                          <img
                            key={i}
                            src={photo}
                            alt="gallery"
                            className="object-cover object-center w-full p-1"
                          />
                        );
                      })}
                    </div>

                    <p className="text-sm text-left text-gray-600 ">
                      {item.galleryCaption}
                    </p>
                  </div>
                );
              }

              case "youtube":
                return (
                  <div key={i}>
                    <div className="my-5 aspect-w-16 aspect-h-9">
                      <iframe
                        src={`https://www.youtube.com/embed/${item.embedId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        className="p-2 mr-3 border border-gray-500"
                      />
                    </div>
                  </div>
                );
              default:
                return null;
            }
          })}
          <div className="flex justify-center w-full py-3 ">
            <a
              target="_blank"
              href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.singaporewomenassociation.org%2Fevents%2F${event.slug}&amp;src=sdkpreparse`}
              className="fb-xfbml-parse-ignore"
            >
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 space-x-2 text-sm font-medium text-white border border-transparent rounded shadow-sm bg-swa-fb hover:bg-swa-fb focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-fb"
              >
                <FaFacebookF />
                <p>Share</p>
              </button>
            </a>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default EventPageTemplate;
